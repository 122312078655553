
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        






























.campaign-setup-pixels-list-item-skeleton {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 $--clb-space-3;
  border: 1px solid $--clb-border-color-base;
  border-radius: $--clb-border-radius;

  .campaign-setup-pixels-list-item__header-middle {
    margin-right: 60px;
    justify-content: center;

    p {
      height: 16px;
      display: flex;
    }
  }

  @media (min-width: $--sm) {
    .campaign-setup-pixels-list-item__header-middle {
      margin-right: 176px;

      .skeleton-box {
        height: 16px !important;
      }
    }
  }
}
