
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        


















































.campaign-setup-pixels-general {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__heading {
    font-size: $--clb-h3__font-size !important;
    color: black;
    margin-bottom: 30px;
  }

  &__item {
    width: 100%;
    margin-bottom: 0;
  }

  &__input {
    display: flex;
  }

  .el-form-item__error {
    top: 40px;
    left: 0;
  }
}
