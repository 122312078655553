
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        























































































.el-form-item.is-success:not(:hover) {
  .el-input__inner,
  .el-textarea__inner {
    &:not(:focus):not(:active) {
      border-color: $--clb-border-color-base;
    }
  }
}
