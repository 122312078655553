
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        

























































































































































.campaign-setup-pixels-code {
  margin-bottom: 0 !important;

  &__heading {
    display: flex;
    justify-content: space-between;
  }

  .el-form-item {
    margin-bottom: 0;
    transition: $--all-transition;

    &__error {
      position: relative;
      top: 0;
      left: 0;
    }
  }

  &__macros {
    margin-top: $--clb-space-2;
    display: flex;
    white-space: nowrap;

    label {
      margin: 0;
      line-height: 18px;
      font-size: $--clb-font-size-sm;
    }

    i {
      color: $--clb-color-accent__dark;
    }

    &-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;

      &-item {
        padding-left: $--clb-space-2;
        line-height: 18px;

        a {
          font-weight: 400;
          font-size: $--clb-font-size-xs;
          text-transform: uppercase;
          line-height: 14px;
          border-right: 1px solid $--clb-border-color-base;
          padding-right: $--clb-space-2;
        }

        &:last-child a {
          border-right: none;
          padding-right: 0;
        }
      }
    }
  }
}
