
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        








































.campaign-setup-pixels-list {
  .campaign-setup-pixels-list-item-skeleton {
    pointer-events: none !important;
  }
}
