
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        





































































































































































































































.campaign-setup-pixels {
  @include page-layout;

  @apply tw-pt-0;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;

  &-header {
    @apply tw-flex tw-transition tw-text-center tw-items-center tw-mx-space-3 tw-mb-space-4 sm:tw-mx-space-4;

    p {
      font-size: $--clb-font-size-xs;
      font-weight: 700;
      flex: 1 1 0;
    }

    &__left {
      flex: 3 1 0;
      text-align: left;
    }

    &__middle {
      flex: 2;
      display: flex;
    }

    &__right {
      width: 60px;
    }

    .campaign-setup-pixels__title {
      margin: 0;
    }
  }

  &-list-item {
    margin-bottom: $--clb-space-3;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $--clb-layout-1;
    border-radius: $--clb-border-radius;
    background: $--clb-skeleton-color;
    transition: $--all-transition;
    cursor: pointer;

    @apply tw-bg-jb-grey-50;

    &:hover {
      transform: translateY(-1px);
      box-shadow: $--box-shadow-dark;

      h6 {
        color: $--jb-light-primary-color;
      }
    }
  }

  .el-button {
    margin: 0;
    padding: 0;
    height: 36px;
    width: 36px;
    font-size: $--clb-font-size-xs;

    span {
      display: none;
    }

    i {
      font-size: $--clb-font-size-sm;
    }

    &:active,
    &:hover,
    &:focus {
      transform: translateY(0) !important;
    }
  }
}

@media (min-width: $--sm) {
  .campaign-setup-pixels {
    &-header {
      &__left {
        margin-right: -$--clb-space-4;
      }

      &__right {
        width: 176px;
      }
    }

    &__footer {
      h6 {
        font-size: $--clb-font-size-base;
      }
    }

    .el-button {
      width: 88px;
      height: 36px;

      span {
        display: inline;
      }
    }
  }
}
