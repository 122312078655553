
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        




















































































.campaign-setup-pixels-platform {
  &__action-container {
    display: block;
  }

  .el-dropdown-link {
    display: flex;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    border: 1px solid $--clb-border-color-base;
    border-radius: $--clb-border-radius;
    padding: 8px;
    outline: transparent;

    &:hover,
    &:focus {
      border-color: $--clb-color-primary;
      color: $--clb-color-primary;
    }
  }

  &__select {
    width: 100%;
  }
}
