
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        









































































.campaign-setup-pixels-event-filter {
  .el-input {
    .el-input__inner {
      padding-left: $--clb-space-4;
    }
  }

  .el-select {
    .el-input {
      min-width: 110px;
    }
  }
}
