
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        











































































































.campaign-setup-pixels-domain {
  &__action-container {
    display: block;
  }

  .el-dropdown-link {
    display: flex;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    border: 1px solid $--clb-border-color-base;
    border-radius: $--clb-border-radius;
    padding: 8px;
    outline: transparent;

    &:hover,
    &:focus {
      border-color: $--clb-color-primary;
      color: $--clb-color-primary;
    }
  }

  &__select {
    width: 100%;
  }

  &__label-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__manage-link {
    font-size: $--clb-font-size-sm;
  }
}

.campaign-setup-pixels-domain__select-menu {
  .campaign-setup-pixels-domain__select-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .campaign-setup-pixels-domain__select-item-tags {
      span {
        margin-left: $--clb-space-2;
        font-size: 12px;
        padding: $--clb-space-1 $--clb-space-2;
        border-radius: $--clb-border-radius / 2;
        color: $--clb-color-primary__white;

        &.tag-default {
          background-color: $--clb-color-grey__grey;
        }

        &.tag-success {
          background-color: $--clb-color-green;
        }

        &.tag-danger {
          background-color: $--clb-color-red;
        }
      }
    }
  }
}
